<template style="z-index:1000">
	<div>
		<b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
			sidebar-class="sidebar-lg" shadow backdrop no-header right
			@change="(val) => $emit('update:is-add-new-user-sidebar-active', val)" />
		<template>
			<!-- {{ loader }} -->
			<div v-if="loader" class="loader"></div>
			<div :class="[loader ? 'backdrop' : '']">

				<h2>
					<center>Store</center>
				</h2>
				<br /><br />
				<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
					<h5 class="mb-0">
						Add Store
					</h5>


				</div>
				<validation-observer ref="registerForm">
					<b-form @reset="onReset" class="auth-register-form mt-2 ml-2 mr-2">
						<b-form-group label="File" label-for="file">
							<b-form-file accept=".csv" v-on:change="uploadCsv($event)" v-model="file" />
						</b-form-group>



						<b-button variant="primary" size="lg" type="submit" @click.prevent="addInStore">
							Save
						</b-button>
						<br /><small class="text-danger">{{ errors }}</small>
					</b-form>
					<b-modal ok-only v-model="success">
						Your Store Data is uploaded!
					</b-modal>
				</validation-observer>
				<!-- <h4 @click="deleteHandler" class="xy mt-2">Delete store</h4> -->
			</div>

		</template>

	</div>
</template>
<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BSidebar, BModal, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapMutations, mapActions, mapState } from "vuex";
import Password from "vue-password-strength-meter";
import { uploadFile } from "../../../s3upload/index";
import VuePapaParse from 'vue-papa-parse'
import Papa from 'papaparse';
import axios from 'axios';

export default {
	components: {
		BSidebar,
		BForm,
		BFormFile,
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		BButton,
		vSelect,
		BModal,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
		VuePhoneNumberInput,
		Password
	},
	model: {

	},
	props: {


	},
	data() {
		return {
			file: '',
			content: [],
			parsed: false,
			image: '',
			loader: false,
			// loader: true,
		}
	},
	methods: {
		setLoader(booli) {
			this.loader = booli;
		},
		...mapActions({
			addStore: "adminStore/addStore",
			deleteStore: "adminStore/deleteStore"
		}),
		async deleteHandler() {
			await this.deleteStore();
		},

		onReset() {
			//resetform
			this.file = '',
				this.content = [],
				this.parsed = false,
				this.image = ''
				
		},
		async uploadCsv() {
			this.file = event.target.files[0];
			await this.parseFile();
		},
		async parseFile() {
			await Papa.parse(this.file, {
				header: true,
				skipEmptyLines: true,
				complete: function (results) {
					this.content = results;
					this.parsed = true;
					console.log(results)
				}.bind(this)
			});
		},
		async addInStore() {
			console.log('file -', this.file)
			console.log('content -', this.content.data)
			console.log('parsed -', this.parsed)
			this.setLoader(true);
			await this.addStore(this.content.data)
			this.setLoader(false);
			// this.success = true
			this.onReset()
		}

	},
	computed: {
		...mapState({
			errors: (state) => state.adminStore.errors,
			success: (state) => state.adminStore.success
		}),
	},
}

</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
	

<style scoped>
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -100px;
	margin-left: -100px;
	width: 48px;
	height: 48px;
	border: 5px solid #4385f5;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.backdrop {
	/* position: fixed; */
	/* background: rgba(0, 0, 0, 0.5); */
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.3;
	pointer-events: none;
}
</style>